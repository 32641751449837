import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

import { PageMeta } from "../components/pageMeta"
import BlockZone from "~blockZone"
import { HeroBanner } from '../components/blockZone/blocks'

const IndexPage = ({ data: { page } }) => {
  const { meta, pageBlocks, banner } = page || {}

  const { toggleHeaderLight } = useAppState();

  useEffect(() => {
    toggleHeaderLight(true);
  }, [toggleHeaderLight]);

  return (
    <>
      <PageMeta {...meta} />
      <HeroBanner {...banner}/>
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      banner {
        heading
        rotatingWords
        video
        image {
          ...ImageWithPreview
        }
      }
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
